import appStyles from './App.module.scss';
import Footer from './pages/Footer/Footer';
import Header from './pages/Header/Header';
import InfoPage from './pages/InfoPage/InfoPage';
import { useState } from 'react';
import { BoTod } from 'components/boTod';
import { BoTodStore } from 'store/BoTodStore';
import { CubeStatus } from 'components/boTod/cube.types';
import { observer } from 'mobx-react-lite';
import { SetupNode } from 'pages/SetupNode';

const App = observer(() => {
    const { staticBoTod, fixedBoTod, changeStatuses, changeFixedStatus } = BoTodStore;
    const [isLight, setIsLight] = useState(false);
    const [isStartNodeVisible, setIsStartNodeVisible] = useState(false);

    const showStartNodeTutorial = () => {
        document.body.style.overflow = 'hidden';
        if (staticBoTod.current !== CubeStatus.loading) {
            const prevStaticStatus =
                staticBoTod.current === CubeStatus.appear || staticBoTod.current === CubeStatus.disappear
                    ? staticBoTod.past
                    : staticBoTod.current;
            const staticStatuses = {
                current: CubeStatus.disappear,
                past: prevStaticStatus,
            };
            const fixedStatuses = {
                current: CubeStatus.appear,
                past: CubeStatus.waiting,
            };
            changeStatuses(staticStatuses, fixedStatuses);
            setTimeout(() => {
                changeFixedStatus({
                    current: CubeStatus.grab,
                    past: CubeStatus.appear,
                });
            }, 1700);
        }
    };
    return (
        <div className={`${appStyles.app} ${isLight ? appStyles.light : ''}`}>
            <Header setIsLight={setIsLight} isLight={isLight} />
            <InfoPage
                isLight={isLight}
                showStartNodeTutorial={showStartNodeTutorial}
                setIsStartNodeVisible={setIsStartNodeVisible}
            />
            <Footer isLight={isLight} />
            {(fixedBoTod.current === CubeStatus.appear || fixedBoTod.current === CubeStatus.grab) && (
                <BoTod statuses={fixedBoTod} isFixed={true} />
            )}
            {
                // eslint-disable-next-line max-len
                fixedBoTod.current !== CubeStatus.appear &&
                    fixedBoTod.current !== CubeStatus.grab &&
                    isStartNodeVisible && (
                        <div className={appStyles.button} onClick={showStartNodeTutorial}>
                            Start Node
                        </div>
                    )
            }
            {fixedBoTod.current === CubeStatus.grab && <SetupNode isLight={isLight} />}
        </div>
    );
});

export default App;
