import { useState, useEffect } from 'react';
import Slider from '../../../../components/Slider';
import RewardLabel from '../RewardLabel';
import styles from './styles.module.scss';

function Reward({ isLight }: { isLight: boolean }) {
    const [state, setState] = useState({ speed: 10, size: 120 });
    const [showHint, setShowHint] = useState(true);

    const changeSize = (value: number) => {
        setState({
            ...state,
            size: value,
        });
    };

    useEffect(() => {
        if (localStorage.getItem('tutorialCompleted')) {
            setShowHint(false);
        }
    }, []);

    return (
        <div className={`${styles.container} ${isLight ? styles.light : ''}`}>
            <h2 className={styles.title}>Calculate your reward</h2>
            <div className={styles.reward}>
                <div className={styles.rewardSliders}>
                    <Slider
                        initialValue={state.size}
                        barsCount={60}
                        maxValue={1000}
                        title='Storage'
                        titleSize='1000 TB'
                        onChange={changeSize}
                        isLight={isLight}
                        showHint={showHint}
                        setShowHint={setShowHint}
                    />
                </div>
            </div>
            <RewardLabel isLight={isLight}>
                {`${state.size} TB / ${Math.ceil((state.size * 3.5) / 0.14)} GXD / $ ${state.size * 3.5}`}
            </RewardLabel>
        </div>
    );
}

export default Reward;
