import { BigCube } from './bigCube';
import { CubeColors, CubeMode, CubeStatus, MediumCubeName, SmallCubeName } from './cube.types';
import { MediumCube } from './mediumCube';
import { SmallCube } from './smallCube';
import styles from './style.module.scss';

type AnimationProps = {
    statuses: {
        current: CubeStatus;
        past: CubeStatus;
    };
    isFixed?: boolean;
};

export const getValidPastStatus = ({ current, past }: { current: CubeStatus; past: CubeStatus }): CubeStatus => {
    if (current === CubeStatus.appear || current === CubeStatus.disappear) {
        return past;
    }
    return current;
};

const getModeForBotod = ({ current, past }: { current: CubeStatus; past: CubeStatus }) => {
    if (current === CubeStatus.appear && past === CubeStatus.success) {
        return CubeMode.afterSuccess;
    }
    if (current === CubeStatus.disappear && past === CubeStatus.success) {
        return CubeMode.afterSuccess;
    }
    if (past === CubeStatus.error) {
        return CubeMode.afterError;
    }
    if (past === CubeStatus.loading) {
        return CubeMode.afterLoading;
    }
    if (past === CubeStatus.waiting) {
        return CubeMode.afterWaiting;
    }
    if (past === CubeStatus.success) {
        return CubeMode.afterSuccess;
    }
    return CubeMode.afterWaiting;
};
const getBotodColor = ({ current, past }: { current: CubeStatus; past: CubeStatus }) => {
    if ((current === CubeStatus.appear && past === CubeStatus.success) || current === CubeStatus.success) {
        return CubeColors.green;
    }
    if ((current === CubeStatus.appear && past === CubeStatus.error) || current === CubeStatus.error) {
        return CubeColors.red;
    }
    return CubeColors.blue;
};

export const BoTod = ({ statuses, isFixed }: AnimationProps) => {
    const mode = getModeForBotod(statuses);
    const BotodColor = getBotodColor(statuses);
    return (
        <div
            className={`${styles.boTod} ${isFixed && styles.fixed} ${
                statuses.current === CubeStatus.grab && styles.moving
            }`}
        >
            <div className={styles.cubes}>
                <BigCube status={statuses.current} mode={mode} color={BotodColor} />
                <MediumCube type={MediumCubeName.T} status={statuses.current} color={BotodColor} mode={mode} />
                <MediumCube type={MediumCubeName.R} status={statuses.current} color={BotodColor} mode={mode} />
                <MediumCube type={MediumCubeName.B} status={statuses.current} color={BotodColor} mode={mode} />
                <MediumCube type={MediumCubeName.L} status={statuses.current} color={BotodColor} mode={mode} />
                <SmallCube type={SmallCubeName.LT} status={statuses.current} color={BotodColor} mode={mode} />
                <SmallCube type={SmallCubeName.RT} status={statuses.current} color={BotodColor} mode={mode} />
                <SmallCube type={SmallCubeName.LB} status={statuses.current} color={BotodColor} mode={mode} />
                <SmallCube type={SmallCubeName.RB} status={statuses.current} color={BotodColor} mode={mode} />
            </div>
            {statuses.current === CubeStatus.appear && <div className={styles.portal} />}
            {statuses.current === CubeStatus.disappear && <div className={styles.portal} />}
        </div>
    );
};
