import styles from './index.module.scss';
import { ReactComponent as LogoBody } from './parts/LogoBody.svg';
import { ReactComponent as LogoBottom } from './parts/LogoBottom.svg';

export const Logo = ({ isLight }: { isLight: boolean }) => {
    return (
        <div className={`${styles.logoContainer} ${isLight ? styles.light : ''}`}>
            <LogoBody className={styles.top} />
            <div className={styles.middle}>
                <LogoBottom />
                <LogoBottom />
            </div>
            <LogoBottom className={styles.bottom} />
        </div>
    );
};
