import styles from './masterNodeRequirements.module.scss';
import { ReactComponent as RightBorderBg } from '../../../../assets/img/rightSideMasterNodeBorder.svg';
import { ReactComponent as LeftBorderBg } from '../../../../assets/img/leftSideMasterNodeBorder.svg';

export const MasterNodeRequirements = ({ isLight }: { isLight: boolean }) => {
    const options = [
        'Rack 24U 1x',
        'Supermicro 2U GPU Lines server 4x',
        'Intel Xeon LGA-4189 16-core processor 4x',
        'NVMe SSD 4Tb Gen 3/4 2.5” hot swap drive 16x',
        'GPU PNY NVIDIA A40 48GB – 4x',
        'HPE FlexNetwork 5130 HI Switch Series 1x',
        'Accessories',
    ];
    return (
        <div className={`${styles.wrapper} ${isLight ? styles.light : ''}`}>
            <h2 className={styles.title}>
                <span className={styles.specialTitle}>Master Node</span> requirements
            </h2>
            <div className={styles.blockWrapper}>
                <h2 className={styles.title}>Typical 4-node NVM + GPU installation:</h2>
                <div className={styles.optionsList}>
                    {options.map((option, i) => (
                        <div key={option + i} className={styles.optionWrapper}>
                            <div className={styles.optionPoint} />
                            <div className={styles.optionText}>{option}</div>
                        </div>
                    ))}
                </div>
                <RightBorderBg className={styles.rightBorderBg} />
                <LeftBorderBg className={styles.leftBorderBg} />
            </div>
        </div>
    );
};
