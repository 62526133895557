import { useState } from 'react';
import { Docker } from './DockerTab/Docker';
import styles from './osTabs.module.scss';

export default function OsTabs({ isLight }: { isLight: boolean }) {
    const [activeTab, setActiveTab] = useState('Docker');
    const tabsButtons = ['Docker', 'Windows', 'Linux', 'MacOS', 'Rewards'];
    const tabContent: { [key: string]: JSX.Element } = {
        Docker: <Docker isLight={isLight} />,
        Windows: <div className={styles.willBeSoon}>will be soon</div>,
        Linux: <div className={styles.willBeSoon}>will be soon</div>,
        MacOS: <div className={styles.willBeSoon}>will be soon</div>,
        Rewards: <div className={styles.willBeSoon}>will be soon</div>,
    };
    return (
        <div className={`${styles.tabsContainer} ${isLight ? styles.light : ''}`}>
            <div className={styles.tabButtonsWrapper}>
                {tabsButtons.map((tab, i) => {
                    return (
                        <div className={`${styles.tabButton} `} key={tab + i} onClick={() => setActiveTab(tab)}>
                            <span className={`${styles.horizontal_faces} ${activeTab === tab && styles.active} `} />
                            <span className={`${styles.vertical_faces} ${activeTab === tab && styles.active}`} />
                            {tab}
                        </div>
                    );
                })}
            </div>
            <div className={styles.tabsContent}>{tabContent[activeTab]}</div>
        </div>
    );
}
