import { CubeStatus } from 'components/boTod/cube.types';
import { useState } from 'react';
import { BoTodStore } from 'store/BoTodStore';
import { Canvas } from './background';
import { Steps } from './Steps';
import styles from './styles.module.scss';
import { ReactComponent as Step } from './step.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Arrow } from '../../assets/img/Arrow.svg';
import { ReactComponent as ArrowDesktop } from '../../assets/img/modalArrowDesktop.svg';

const STEPS_COUNT = 5;
const steps = [0, 1, 2, 3, 4];
export const SetupNode = ({ isLight }: { isLight: boolean }) => {
    const { staticBoTod, changeStatuses } = BoTodStore;
    const [stepIndex, setStepIndex] = useState(0);

    return (
        <div className={`${styles.setup} ${isLight ? styles.light : ''}`}>
            <Canvas isLight={isLight} />
            <div className={styles.setupPage}>
                <div className={styles.numberOfStepsBoxes}>
                    {steps.map((el) => {
                        return (
                            <Step
                                key={`step${el}`}
                                className={`${styles.step} 
                ${stepIndex === el ? styles.active : ''} 
                ${stepIndex > el ? styles.read : ''} `}
                                onClick={() => {
                                    setStepIndex(el);
                                }}
                            />
                        );
                    })}
                </div>
                <div className={styles.steps}>
                    <Steps index={stepIndex} isLight={isLight} />
                </div>
                <div className={styles.fixedButtonsMobile}>
                    {stepIndex > 0 && (
                        <Arrow
                            className={styles.arrowLeft}
                            onClick={() => {
                                setStepIndex((count) => count - 1);
                            }}
                        ></Arrow>
                    )}
                    <div className={styles.stepBoxesMobile} />
                    <Arrow
                        className={styles.arrowRight}
                        onClick={() => {
                            if (stepIndex !== STEPS_COUNT - 1) {
                                setStepIndex((count) => count + 1);
                            }
                            if (stepIndex === STEPS_COUNT - 1) {
                                document.body.style.overflow = 'initial';
                                changeStatuses(
                                    {
                                        current: CubeStatus.appear,
                                        past: staticBoTod.past,
                                    },
                                    {
                                        current: CubeStatus.disappear,
                                        past: CubeStatus.waiting,
                                    },
                                );
                            }
                        }}
                    ></Arrow>
                </div>
                <div className={styles.fixedButtonsDesktop}>
                    {stepIndex > 0 && (
                        <div
                            className={styles.arrowLeftDesktop}
                            onClick={() => {
                                setStepIndex((count) => count - 1);
                            }}
                        >
                            <ArrowDesktop />
                        </div>
                    )}
                    <div
                        className={styles.arrowRightDesktop}
                        onClick={() => {
                            if (stepIndex !== STEPS_COUNT - 1) {
                                setStepIndex((count) => count + 1);
                            }
                            if (stepIndex === STEPS_COUNT - 1) {
                                document.body.style.overflow = 'initial';
                                changeStatuses(
                                    {
                                        current: CubeStatus.appear,
                                        past: staticBoTod.past,
                                    },
                                    {
                                        current: CubeStatus.disappear,
                                        past: CubeStatus.waiting,
                                    },
                                );
                            }
                        }}
                    >
                        <ArrowDesktop />
                    </div>
                </div>
                <Close
                    className={styles.closeButton}
                    onClick={() => {
                        document.body.style.overflow = 'initial';
                        changeStatuses(
                            {
                                current: CubeStatus.appear,
                                past: staticBoTod.past,
                            },
                            {
                                current: CubeStatus.disappear,
                                past: CubeStatus.waiting,
                            },
                        );
                    }}
                />
            </div>
        </div>
    );
};
