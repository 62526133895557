import { useEffect, useState } from 'react';
import { api } from '../../../api/api';
import styles from './Traffic.module.scss';
import SpeedometerBG from '../../../assets/img/Speedometer_BG.svg';
import SpeedometerBGLight from '../../../assets/img/Speedometer_BG_light.svg';
// import BandWidth from './BandWidth';

// max percentage - 65%

export default function Traffic({ peerID, isLight }: { peerID: string; isLight: boolean }) {
    const [ipfsVersion, setIpfsVersion] = useState('');
    const [traffic, setTraffic] = useState({
        totalIn: {
            size: 0,
            unit: '',
            percentage: 0,
        },
        totalOut: {
            size: 0,
            unit: '',
            percentage: 0,
        },
    });

    const random = +(Math.random() * (2.5 - 1.5) + 1.5).toFixed(1);

    useEffect(() => {
        api.get('ipfs/version').then((response) => {
            setIpfsVersion(response.data);
        });
        api.get(`ipfs/peer/bandwidth/${peerID}`).then((response) => {
            setTraffic({
                totalIn: getInfo(response.data.TotalIn),
                totalOut: getInfo(response.data.TotalOut),
            });
        });
    }, []);

    const getInfo = (kb: number) => {
        const maxPercent = 65;
        const maxKb = kb * random;
        const onePercent = +(maxKb / maxPercent).toFixed(0);
        const percentage = +(kb / onePercent).toFixed(0);

        const mb = kb / 1000;
        const gb = mb / 1000;
        const tb = gb / 1000;

        if (tb > 1) {
            return { size: +tb.toFixed(0), unit: 'Tb', percentage };
        }
        if (gb > 1) {
            return { size: +gb.toFixed(0), unit: 'Gb', percentage };
        }
        if (mb > 1) {
            return { size: +mb.toFixed(0), unit: 'Mb', percentage };
        }
        return { size: kb, unit: 'Kb', percentage };
    };

    return (
        <div className={`${styles.wrapper} ${isLight ? styles.light : ''}`}>
            <div className={styles.firstBlock}>
                <div>
                    <p className={`${styles.title} ${styles.uppercase}`}>Network traffic</p>
                    <div className={styles.speedometersBlock}>
                        {
                            <div
                                style={{
                                    //@ts-ignore
                                    '--final-percentage': `${traffic.totalOut.percentage}%`,
                                    '--final-kilobyte': traffic.totalOut.size,
                                    '--color': '#7D2EFF',
                                }}
                            >
                                <div className={styles.speedometer_container}>
                                    <img
                                        className={styles.background}
                                        src={isLight ? SpeedometerBGLight : SpeedometerBG}
                                        alt='Speedometer'
                                    />
                                    <div className={styles.speedometer} />
                                    <div className={styles.speedometer_type_container}>
                                        <div className={styles.type}>Outgoing</div>
                                        <div className={styles.kilobyte}>
                                            <span className={styles.final_kb}></span>
                                            <span className={styles.kb}>{traffic.totalOut.unit}/s</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            <div
                                style={{
                                    //@ts-ignore
                                    '--final-percentage': `${traffic.totalIn.percentage}%`,
                                    '--final-kilobyte': traffic.totalIn.size,
                                    '--color': '#05F0FD ',
                                }}
                            >
                                <div className={styles.speedometer_container}>
                                    <img
                                        className={styles.background}
                                        src={isLight ? SpeedometerBGLight : SpeedometerBG}
                                        alt='Speedometer'
                                    />
                                    <div className={styles.speedometer} />
                                    <div className={styles.speedometer_type_container}>
                                        <div className={styles.type}>Incoming</div>
                                        <div className={styles.kilobyte}>
                                            <span className={styles.final_kb}></span>
                                            <span className={styles.kb}>{traffic.totalIn.unit}/s</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className={styles.titleWrap}>
                    <p className={styles.title}>
                        <div>Peer ID: </div>
                        <div className={styles.peerId}>{peerID}</div>
                    </p>
                    <div className={styles.boxes}>
                        <div className={styles.box}>
                            <p>Green</p> <span>Node health</span>
                        </div>
                        <div className={styles.box}>
                            <p>{ipfsVersion}</p> <span>IPFS ver</span>
                        </div>
                        <div className={styles.box}>
                            <p>0.0.0.1</p>
                            <span>UI ver</span>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className={styles.bandWidth}>
        <BandWidth />
      </div> */}
            {/* <div className={styles.accuredRewardsBlock}>
        <p className={styles.title}>Accrued Rewards</p>
        <div className={styles.gridTable}>
          <div className={styles.gridTitle}>
            <p>Amount</p>
            <p>Date</p>
          </div>
          <div className={styles.gridCell}>
            <p>377 000 GXD</p>
            <p>05/02/2020 3. a.m</p>
          </div>
          <div className={styles.gridCell}>
            <p>377 000 GXD</p>
            <p>05/02/2020 3. a.m</p>
          </div>
          <div className={styles.gridCell}>
            <p>377 000 GXD</p>
            <p>05/02/2020 3. a.m</p>
          </div>
        </div>
      </div> */}
        </div>
    );
}
