import styles from './systemRequirements.module.scss';
import { ReactComponent as TableBorderBg } from '../../../../assets/img/recommendedSystemTableBorder.svg';
import { ReactComponent as RectangleBorder } from '../../../../assets/img/recommendedSystemRectangleBg.svg';

export const SystemRequirements = ({ isLight }: { isLight: boolean }) => {
    const tableOptions = [
        'CPU 12 cores / 24 threads, or more, 2.8GHz, or faster AVX2 instruction support (to use official release binaries, self-compile otherwise) Support for AVX512f and/or SHA-NI instructions is helpful The AMD Zen3 series is popular with the validator community',
        'RAM 128GB, or more',
        'Motherboard with 256GB capacity suggested',
        'Disk PCIe Gen4 NVME SSD Accounts: 500GB, or larger. High TBW (Total Bytes Written) Ledger: 64TB or larger. High TBW suggested',
        'OS: (Optional) 10GB, or larger. SATA OK The Samsung 970 and 980 Pro series SSDs are popular with the validator community',
        'GPUs Not strictly necessary at this time Motherboard and power supply speed to add one or more high-end GPUs in the future suggested',
    ];
    return (
        <div className={`${styles.wrapper} ${isLight ? styles.light : ''}`}>
            <div className={styles.blockWrapper}>
                <h2 className={styles.title}>
                    System <span className={styles.titleSpecialBlue}>Requirements</span>
                </h2>
                <p className={styles.text}>
                    IPFS requires 512MiB of memory and can run an IPFS node on a Raspberry Pi. However, how much disk
                    space your IPFS installation takes up depends on how much data you're sharing. A base installation
                    takes up about 12MB of disk space, and the default maximum disk storage is set to 10GB. IPFS can run
                    on most Linux, macOS, and Windows systems.
                </p>
            </div>
            <div className={styles.blockWrapper}>
                <h2 className={styles.title}>
                    <span className={styles.titleSpecialTurquoise}>Recommended </span>
                    system specifications:
                </h2>
                <div className={styles.recommendedSystemTable}>
                    {tableOptions.map((option, i) => (
                        <div key={option + i} className={styles.tableOptionWrapper}>
                            <div className={styles.tablePoint} />
                            <div className={styles.tableOptionText}>{option}</div>
                        </div>
                    ))}
                    <TableBorderBg className={styles.recommendedSystemTableBorder} />
                    <RectangleBorder className={styles.rectangle} />
                </div>
            </div>
            <div className={styles.rightBackgroundLight} />
        </div>
    );
};
