import styles from './docker.module.scss';

export const Docker = ({ isLight }: { isLight: boolean }) => {
    return (
        <div className={`${styles.wrapper} ${isLight ? styles.light : ''}`}>
            <div className={styles.boxWrapper}>
                <div className={styles.boxContent}>
                    <p>
                        {
                            'bash <(curl -s https://raw.githubusercontent.com/Ghost-Drive/ghost-node/main/get-gxd-docker.sh'
                        }
                    </p>
                    <div className={styles.copyLinkIcon} />
                </div>
            </div>
            <div className={styles.boxWithTitle}>
                <h3 className={styles.title}>or</h3>
                <div className={styles.boxWrapper}>
                    <div className={styles.boxContent}>
                        <p>
                            {
                                'bash <(wget -qO -  https://raw.githubusercontent.com/Ghost-Drive/ghost-node/main/get-gxd-docker.sh)'
                            }
                        </p>
                        <div className={styles.copyLinkIcon} />
                    </div>
                </div>
            </div>
            <div className={styles.boxWithTitle}>
                <h3 className={styles.specialTitle}>
                    Alternatively, if you wish to manually build the Docker file, clone the project and simply run:
                </h3>
                <div className={styles.boxWrapper}>
                    <div className={styles.boxContent}>
                        <p>{'docker build -t ghost-ipfs-gen4-node ./docker'}</p>
                        <div className={styles.copyLinkIcon} />
                    </div>
                </div>
            </div>
        </div>
    );
};
