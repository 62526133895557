import styles from './networkDescription.module.scss';
import { ReactComponent as LeftBorderBg } from '../../../../assets/img/leftSideNetworkDescrBgBorder.svg';
import { ReactComponent as RightBorderBg } from '../../../../assets/img/rightSideNetworkDescrBgBorder.svg';

export const NetworkDescription = ({ isLight }: { isLight: boolean }) => {
    return (
        <div className={`${styles.wrapper} ${isLight ? styles.light : ''}`}>
            <div className={styles.descriptionBlock}>
                <p className={styles.title}>
                    <span>GxD Network</span> Description (P2P)
                </p>
                <div className={styles.textBlockContent}>
                    <p>
                        <span className={styles.mobileText}>
                            GhostDrive provides a robust solution for secure data storage through the implementation of
                            encryption and network verification protocols. The fundamental shift that GhostDrive
                            facilitates is a foundational trustless assurance of privacy with complete traceability
                            enabled by utility encryption tokens.
                        </span>
                        <span className={styles.mobileText}>
                            GhostDrive is designed to run on almost any smart contract blockchain, including Solana,
                            Ethereum, Polygon, Binance Smart Chain, or a private server network. However, its
                            functionality is always facilitated by the GXD Utility Encryption Token, the underlying
                            token of the GXD Network
                        </span>
                    </p>
                    <LeftBorderBg className={styles.leftBorderBg} />
                    <RightBorderBg className={styles.rightBorderBg} />
                </div>
            </div>
            <div className={styles.gxdIncludesPointsBlock}>
                <p>
                    Support of the node layer and data storage for the GhostDrive ecosystem is done through validation
                    by the network of GXD token holders. GXD tokens serve as Proof of Security for the encryption and
                    transmission of files, including:
                </p>
                <div className={styles.gxdPointsList}>
                    <div className={styles.conceptsWrap}>
                        <div className={styles.concepts}>
                            <div className={styles.conceptsBlock}>
                                <div className={styles.concept}>
                                    <div>
                                        <span>Proof of storage space</span>
                                    </div>
                                </div>
                                <div className={styles.concept}>
                                    <div>
                                        <span>Proof of file transmission</span>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.cubes} />
                            <div className={styles.conceptsBlock}>
                                <div className={styles.concept}>
                                    <div>
                                        <span>Proof of encryption</span>
                                    </div>
                                </div>
                                <div className={styles.concept}>
                                    <div>
                                        <span>Proof of file delivery</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.concept}>
                            <div>Proof of file access</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
