import AboutUs from './AboutUs/AboutUs';
import { CheckNode } from './CheckNode/CheckNode';
import styles from './InfoPage.module.css';
import NodeMap from './NodeMap/NodeMap';
import RewardCalculator from './RewardCalculator';
import StartOwnNode from './StartOwnNode/StartOwnNode';

export default function InfoPage({
    isLight,
    showStartNodeTutorial,
    setIsStartNodeVisible,
}: {
    isLight: boolean;
    showStartNodeTutorial: any;
    setIsStartNodeVisible: any;
}) {
    document.addEventListener('scroll', () => {
        if (document.documentElement.scrollTop > 550) {
            setIsStartNodeVisible(true);
        } else {
            setIsStartNodeVisible(false);
        }
    });

    return (
        <div className={styles.content}>
            <StartOwnNode isLight={isLight} showStartNodeTutorial={showStartNodeTutorial} />
            <NodeMap isLight={isLight} />
            <CheckNode isLight={isLight} />
            <RewardCalculator isLight={isLight} />
            <AboutUs isLight={isLight} />
        </div>
    );
}
