import styles from './AboutUs.module.scss';
import { ReactComponent as Icon } from '../../../assets/icons/iconInformationBlock.svg';
import { ReactComponent as Tree } from '../../../assets/img/aboutUsTree.svg';
import { ReactComponent as Field } from '../../../assets/img/aboutUsField.svg';
export default function AboutUs(props: { isLight: boolean }) {
    const information = [
        {
            title: 'Greener',
            description: 'A decentralized internet is faster, greener, safer, and more private by nature.',
        },
        {
            title: 'We plat trees',
            description: 'We donate to support no-profit organization to plan the  tree globally.',
        },
        {
            title: 'Efficient',
            description:
                'Our energy-efficient grid solves this problem at scale, and renders our services substantially less expensive',
        },
        {
            title: 'Green Planet',
            description: 'GhostDrive works to keep both people and the planet healthy. We plan the trees.',
        },
    ];
    return (
        <div className={`${styles.wrapper} ${props.isLight ? styles.light : ''}`}>
            <p id='about-us' className={styles.title}>
                Green Mission Network
            </p>
            <div className={styles.informationBlocks}>
                {information.map(({ title, description }, i) => (
                    <div key={title + i} className={styles.informationBlock}>
                        <Icon className={styles.icon} />
                        <p className={styles.titleBlock}>{title}</p>
                        <span className={styles.description}>{description}</span>
                    </div>
                ))}
            </div>
            <Tree className={styles.tree} />
            <Field className={styles.field} />
        </div>
    );
}
