import styles from './updatingIpfs.module.scss';

export const UpdatingIpfs = ({ isLight }: { isLight: boolean }) => {
    return (
        <div className={`${styles.wrapper} ${isLight ? styles.light : ''}`}>
            <h2 className={styles.title}>
                <span className={styles.specialTitle}>Updating IPFS</span> on your Ghost Node
            </h2>
            <p className={styles.text}>
                IPFS has an updating tool that can be accessed through
                <span className={styles.ipfsUpdateButton}>ipfs update</span>
                {'\n'}
                The tool is not installed alongside IPFS in order to keep that logic independent of the main codebase.
            </p>
            <div className={styles.boxWithTitle}>
                <h3 className={styles.title}>List the available versions of Go-IPFS:</h3>
                <div className={styles.boxWrapper}>
                    <div className={styles.boxContent}>
                        <p>{'$ ipfs cat /ipns/dist.ipfs.io/go-ipfs/versions'}</p>
                        <div className={styles.copyLinkIcon} />
                    </div>
                </div>
            </div>{' '}
            <div className={styles.boxWithTitle}>
                <h3 className={styles.title}>
                    Then, to view available builds for a version from the previous command ($VERSION):
                </h3>
                <div className={styles.boxWrapper}>
                    <div className={styles.boxContent}>
                        <p>{'$ ipfs ls /ipns/dist.ipfs.io/go-ipfs/$VERSION'}</p>
                        <div className={styles.copyLinkIcon} />
                    </div>
                </div>
            </div>
        </div>
    );
};
