import { useState } from 'react';
import Input from '../../components/Input/Input';
import styles from './Footer.module.scss';
import Button, { ButtonState } from '../../components/Button/Button';
import { api } from 'api/api';
import { Logo } from '../../components/Logo/index';
import { ReactComponent as MSocialIcon } from '../../assets/icons/mSocialIcon.svg';
import { ReactComponent as TelegramIcon } from '../../assets/icons/telegramIcon.svg';
import { ReactComponent as GitHubIcon } from '../../assets/icons/gitHubIcon.svg';
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitterIcon.svg';
import { ReactComponent as DiscordIcon } from '../../assets/icons/discordIcon.svg';
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagramIcon.svg';
import { ReactComponent as YoutubeIcon } from '../../assets/icons/youtubeIcon.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/icons/linkedinIcon.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebookIcon.svg';

export default function Footer(props: { isLight: boolean }) {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [status, setStatus] = useState('primary');
    const mailValidator = /^[.\S]{3,}@[A-Za-z]{2,}\.[A-Za-z]{2,}/;

    const checkEmail = () => {
        api.post('subscriber', { email }).then((res) => {
            if (res.error) {
                setStatus('error');
                setError(res.error.email);
            } else {
                if (email.match(mailValidator)) {
                    setStatus('loading');
                    setTimeout(() => {
                        setStatus('success');
                        setEmail('');
                    }, 1500);
                }
            }
        });
    };

    return (
        <div className={`${styles.wrapper} ${props.isLight ? styles.light : ''}`}>
            <div className={styles.emailBlock}>
                <div className={styles.iconInEmailBlock}>
                    <Logo isLight={props.isLight} />
                </div>
                <p>
                    A fast, secure, and highly scalable blockchain platform for distributed apps, enterprise use cases,
                    and the new internet economy.
                </p>
                <div className={styles.inputContainer}>
                    <Input
                        isLight={props.isLight}
                        type={status}
                        placeholder='Enter your email'
                        errorMessage={error}
                        inFooter={true}
                        value={email}
                        onChange={(e: any) => {
                            if (status !== 'primary') {
                                setStatus('primary');
                                setError('');
                            }
                            setEmail(e.target.value);
                        }}
                    />
                    <Button
                        isLight={props.isLight}
                        state={status as ButtonState}
                        text='subsribe'
                        inInput={true}
                        inFooter={true}
                        onClick={checkEmail}
                    />
                </div>
            </div>
            <div className={styles.secondMenu}>
                <div>
                    <div className={styles.navMenuWrapper}>
                        <a className={styles.navItem} href={'/'}>
                            Node Statistic
                        </a>
                        <a className={styles.navItem} href={'/your-node'}>
                            Your Node
                        </a>
                        <a className={styles.navItem} href={'/calculator'}>
                            Calculator
                        </a>
                        <a className={styles.navItem} href={'/what-we-doing'}>
                            What we are doing
                        </a>
                    </div>
                </div>
                <div className={styles.usefulPages}>
                    <a href='#'>Privacy Policy</a>
                    <a href='#'>Terms & Conditions</a>
                </div>
                <div className={styles.joinUsBlock}>
                    <p className={styles.joinUsTitle}>Join us</p>
                    <div>
                        <div className={styles.socialIconsContainer}>
                            <a href='https://ghostdrive.medium.com/' target='_blank' rel='noreferrer'>
                                <MSocialIcon className={styles.socialIcon} />
                            </a>
                            <a href='https://t.me/ghostdrive_web3' target='_blank' rel='noreferrer'>
                                <TelegramIcon className={styles.socialIcon} />
                            </a>
                            <a href='https://github.com/Ghost-Drive/ghost-node' target='_blank' rel='noreferrer'>
                                <GitHubIcon className={styles.socialIcon} />
                            </a>
                            <a href='https://twitter.com/GhostDrive_Web3' target='_blank' rel='noreferrer'>
                                <TwitterIcon className={styles.socialIcon} />
                            </a>
                            <a href='https://discord.com/invite/8eyj5BGqZS' target='_blank' rel='noreferrer'>
                                <DiscordIcon className={styles.socialIcon} />
                            </a>
                        </div>
                        <div className={styles.socialIconsContainer}>
                            <a href='https://www.instagram.com/ghostdrive_web3/' target='_blank' rel='noreferrer'>
                                <InstagramIcon className={styles.socialIcon} />
                            </a>
                            <a href='https://github.com/Ghost-Drive/ghost-node' target='_blank' rel='noreferrer'></a>
                            <a
                                href='https://www.youtube.com/channel/UCjJ01Ma21Sx4cEL3Q9SSGtA'
                                target='_blank'
                                rel='noreferrer'
                            >
                                <YoutubeIcon className={styles.socialIcon} />
                            </a>
                            <a href='https://www.linkedin.com/company/ghostdrive/' target='_blank' rel='noreferrer'>
                                <LinkedInIcon className={styles.socialLinkedin} />
                            </a>
                            <a href='https://www.facebook.com/GhostDrive.Web3/' target='_blank' rel='noreferrer'>
                                <FacebookIcon className={styles.socialIcon} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <p className={styles.joinUsReserved}>Copyright © 2022 ghostdrive LLC. All rights reserved </p>
        </div>
    );
}
