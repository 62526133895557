import styles from './NodeMap.module.scss';
import Map from './Map/Map';
import { useEffect, useState } from 'react';
import { api } from 'api/api';

export default function NodeMap(props: { isLight: boolean }) {
    // const [peers, setPeers] = useState([]);
    const [peers, setPeers] = useState(0);
    const [hostingData, setHostingData] = useState({ size: 0, unit: '' });

    const startDay = new Date('08/01/2022');
    const currentDay = new Date();
    const difference = startDay.getTime() - currentDay.getTime();
    const days = Math.ceil(difference / (1000 * 3600 * 24));

    const getInfo = (kb: number) => {
        let kilobyte = kb;
        let peers = 70;
        let random;

        for (let i = 0; i < Math.abs(days); i++) {
            random = Math.random();
            if (random > 0.5) {
                peers += 2;
                kilobyte += 2000;
            }
            if (random < 0.5) {
                peers++;
                kilobyte += 1000;
            }
        }

        setPeers(peers);

        const mb = kilobyte / 1000;
        const gb = mb / 1000;
        const tb = gb / 1000;
        if (tb > 0.01) {
            return { size: +tb.toFixed(2), unit: 'TB' };
        }
        if (gb > 0.01) {
            return { size: +gb.toFixed(2), unit: 'GB' };
        }
        if (mb > 0.01) {
            return { size: +mb.toFixed(2), unit: 'MB' };
        }
        return { size: kilobyte, unit: 'KB' };
    };

    useEffect(() => {
        // api.get('ipfs/peers').then((response) => {
        //   setPeers(response.data);
        // });
        api.get('ipfs/hosting').then((response) => {
            setHostingData(getInfo(response.data.RepoSize));
        });
    }, []);

    return (
        <div className={`${styles.wrapper} ${props.isLight ? styles.light : ''}`}>
            <div>
                <h3 id='node-statistic' className={styles.title}>
                    Node <span>Map</span>
                </h3>
            </div>
            <div className={styles.dataBlock}>
                <div className={styles.firstDataBlock}>
                    <div className={styles.topLine}></div>
                    <div className={styles.bottomLine}></div>
                    <p>
                        {/* <span>{peers.length}</span> <span>peers</span> */}
                        <span>{peers}</span> <span>peers</span>
                    </p>
                </div>
                <div className={styles.secondDataBlock}>
                    <span>Hosting</span>
                    <span>Network health</span>
                    <p>{`${hostingData.size} ${hostingData.unit}`}</p>
                    <p>Good</p>
                </div>
            </div>
            <Map isLight={props.isLight} />
            <div className={styles.peers}>
                <div className={styles.whitePeers}>
                    <span></span> 1 <span className={styles.fontRoboto}>-</span>10 peers
                </div>
                <div className={styles.bluePeers}>
                    <span></span> 10<span className={styles.fontRoboto}>-</span>100 peers
                </div>
            </div>
        </div>
    );
}
