import { useMemo } from 'react';
import OsTabs from './BuildOsExample/OsTabs';
import { MasterNodeRequirements } from './MasterNodeRequirements/MasterNodeRequirements';
import { NetworkDescription } from './NetworkDescription/networkDescription';
import { SystemRequirements } from './SystemRequirements/systemRequirements';
import { UpdatingIpfs } from './UpdatingIpfs/UpdatingIpfs';

type StepsMemo = {
    [key: number]: JSX.Element;
};

export const Steps = ({ index, isLight }: { index: number; isLight: boolean }) => {
    const networkDescription = useMemo(() => <NetworkDescription isLight={isLight} />, [isLight]);
    const systemRequirements = useMemo(() => <SystemRequirements isLight={isLight} />, [isLight]);
    const docker = useMemo(() => <OsTabs isLight={isLight} />, [isLight]);
    const updatingIpfs = useMemo(() => <UpdatingIpfs isLight={isLight} />, [isLight]);
    const masterNodeRequirements = useMemo(() => <MasterNodeRequirements isLight={isLight} />, [isLight]);
    const stepsMemo: StepsMemo = {
        0: networkDescription,
        1: systemRequirements,
        2: docker,
        3: updatingIpfs,
        4: masterNodeRequirements,
    };
    return <div>{stepsMemo[index]}</div>;
};
